<template>

      <BRow class="justify-content-center align-items-center h-100" style="  margin: 4rem 2rem ; overflow: hidden">
        <BCol md="8" lg="6" xl="5">
          <BCard no-body class="    " bg-variant="dark" shadow-lg>
            <BCardBody class="p-4 text-center"  >
              <div class="avatar-lg mx-auto ">
                <div class="avatar-title    display-3 rounded-circle" style="color: #9FDB48">
                  <i class="ri-checkbox-circle-fill"></i>
                </div>
              </div>
              <div class="mt-4 pt-2">
                <h4>Well done!</h4>
                <p class="text-muted mx-4 my-2 text-center"  style="direction: rtl">
                  شكرا لاشتراكك! سوف تتلقى رابط المحتوى عبر رسالة نصية قصيرة من 3368. لإلغاء الاشتراك في أي وقت أرسل F7 إلى 3368.
                </p>
                <b-button class="mt-4"   to="/"  style="color:#9FDB48" >
              Back to Home
                </b-button>
              </div>
            </BCardBody>
          </BCard>
        </BCol>
      </BRow>



</template>
<style>

</style>
<script>

export default {
  name: 'ThankYouPage'

}
</script>
